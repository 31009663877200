import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { BeatLoader } from 'react-spinners';
import Stack from '@mui/material/Stack';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
// @ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { COLOR_PRIMARY, COLOR_WHITE } from '../../../../../constants/colors';
import { RestrictionsEnum } from '../../../../../constants/restrictionsEnum';
import { fileStatus } from '../../../../../constants/fileStatus';

import RobotAvatar from '../../../../../assets/images/RobotAvatar.png';

import Flex from '../../../../../components/utils/flex/Flex';
import { Chat } from '../../../../../models/Chat';
import UserAvatar from '../../../../../components/UserAvatar/UserAvatar';
import { useStore } from '../../../../../hooks/useStore';
import { findCodeSnippetsInsideString } from '../../../../../utils/findCodeSnippetsInsideString';
import { redactText } from '../../../../../utils/redactText';
import { User } from '../../../../../models/User';
import {
  getBackgroundColorForChat,
  getIconAndTextForBotChatInfo,
  getTextColorForChat,
} from '../../../../../utils/chatMessage';
import BlockedMessageActionComponent from './BlockedMessageAction';
import ChatActions from './ChatActions';
import ChatFileMessageContent from './ChatFileMessageContent';
import { EMPTY_FILE } from '../../../../../models/File';
import FileDoneProcessingContent from './FileDoneProcessingContent';
import { UserFromGetModel } from '../../../../../models/UserModel';
import FileFailedProcessingComponent from './FileFailedProcessingComponent';
import FileCancelledComponent from './FileCancelledComponent';
import ShowSourceDocumentsComponent from './ShowSourceDocumentsComponent';
import ErrorMessageComponent from './ErrorMessageComponent';
import ShowImageComponent from './ShowImageComponent';
import Error from '@mui/icons-material/Error';
import Close from '@mui/icons-material/Close';

const MAX_INTERVAL_TIMEOUT = 10;
const MESSAGE_DIFFERENCE_STEP = 7;

interface ChatMessageProps {
  chat: Chat;
  chatUser: User | UserFromGetModel;
  cursorPosition?: number;
  messageContainerStyles?: SxProps;
  messageContentStyles?: SxProps;
  handleEditPromptExtraAction?: () => void;
  hideActions?: boolean;
}

const ChatMessage = ({
  chat,
  chatUser,
  cursorPosition,
  messageContainerStyles,
  messageContentStyles,
  handleEditPromptExtraAction,
  hideActions,
}: ChatMessageProps) => {
  const {
    localizationStore: { i18next: i18n },
    conversationStore: {
      setMessageInput,
      currentConversation: { chats, files },
    },
  } = useStore();

  const {
    isUserChat,
    loading,
    message,
    wasBlocked,
    error,
    wasWarned,
    wasAnonymized,
    files: messageFiles,
    sourceDocuments,
    imageKey,
    originalMessage,
  } = chat;
  const wasRestricted = wasBlocked || wasWarned || wasAnonymized;

  const isMostRecentUploadedFile =
    messageFiles?.length &&
    Array.isArray(files) &&
    files.length > 0 &&
    files[files.length - 1].id === messageFiles[messageFiles?.length - 1].id;
  const hasUserStartedAskingQuestions = chats.filter(chat => !chat.files?.length).length > 0;

  const [completedTyping, setCompletedTyping] = useState(true);
  const [displayResponse, setDisplayResponse] = useState('');
  const [showOriginalMessage, setShowOriginalMessage] = useState(false);

  const caretRef = useRef<HTMLElement>(null);

  const calculateValue = (messageOneLength: number, messageTwoLength: number) => {
    const difference = Math.abs(messageOneLength - messageTwoLength);

    return MAX_INTERVAL_TIMEOUT - Math.floor(Math.min(difference / MESSAGE_DIFFERENCE_STEP, MAX_INTERVAL_TIMEOUT - 1));
  };

  useEffect(() => {
    let intervalId: any;

    if (!loading) {
      setCompletedTyping(true);
    }

    if (loading || (displayResponse && displayResponse.length < message.length)) {
      setCompletedTyping(false);

      let i = displayResponse?.length || cursorPosition || 0;

      intervalId = setInterval(() => {
        const slicedValue = message.slice(0, i);

        setDisplayResponse(prevValue => {
          if (slicedValue.length > prevValue.length) {
            return slicedValue;
          }

          return prevValue;
        });

        i++;

        setCompletedTyping(false);

        if (slicedValue.length >= message.length && !loading) {
          clearInterval(intervalId);
          setCompletedTyping(true);
        }
      }, calculateValue(displayResponse.length, message.length));
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [chats, message, loading]);

  const messageToDisplay = displayResponse ? displayResponse : chat?.message;

  const messagesArray = findCodeSnippetsInsideString(messageToDisplay);

  const { iconSrc, text, entitiesString } = getIconAndTextForBotChatInfo(chat, i18n);

  if (caretRef.current) {
    caretRef.current.scrollIntoView();
  }

  const getRedactedText = () => {
    if (wasBlocked) {
      return redactText(chat.message, chat.blockedTextIntervals || [], RestrictionsEnum.BLOCKED);
    }

    if (wasWarned) {
      return redactText(chat.message, chat.warnedTextIntervals || [], RestrictionsEnum.WARNING);
    }

    return redactText(chat.message, chat.anonymizedTextIntervals || [], RestrictionsEnum.ANONYMIZE);
  };

  const restrictedChat = (
    <div
      dangerouslySetInnerHTML={{
        __html: getRedactedText(),
      }}
    />
  );

  const botChatInfo = (
    <Flex sx={{ alignItems: 'center', gap: '8px' }}>
      <img src={iconSrc} alt={'bot-chat-icon'} />
      {i18n.t(text, { entitiesString })}
    </Flex>
  );

  const fileMessage = <ChatFileMessageContent files={(messageFiles?.length && messageFiles) || [EMPTY_FILE]} />;

  const checkIsMarkdownTable = (text: string) => {
    const isMarkdownTable =
      /^(\n|\\n|\r)*(\|[^\n]*\|(\n|\\n|\r)*)((?:\|:?[-]+:?)+\|(\n|\\n|\r)*)(\n*(?:\|[^\n]+\|(\n|\\n|\r)*)*)?$/gm;

    const matches = text.match(isMarkdownTable);

    return Array.isArray(matches) && matches.length === 1;
  };

  const isMarkdownTableRenderable = (markdownText: string) => {
    const matchMarkdownTableColumnRegex = /^(\|[^\n]+\|\r?\n)/gm;

    const matches = markdownText.match(matchMarkdownTableColumnRegex);

    if (!matches || matches.length < 2) {
      return false;
    }

    return matches[0].split('|').length === matches[1].split('|').length;
  };

  const formatText = (text: string | null | undefined) => {
    if (!text) {
      return '';
    }

    const regex = /\[([^\]]+)\]\(([^)]+)\)\s+\(([^)]+)\)/g;
    return text.replace(regex, (match, title, url, readingTime) => {
      return `[**${title}**](${url} "Open in new window") (${readingTime})`;
    });
  };

  return (
    <Flex sx={{ paddingTop: '16px', width: '100%' }}>
      {isUserChat ? (
        <UserAvatar user={chatUser} size={40} sx={{ marginRight: '20px' }} />
      ) : (
        <UserAvatar avatar={RobotAvatar} size={40} sx={{ marginRight: '20px' }} />
      )}

      <Stack sx={{ maxWidth: '90%' }}>
        <Flex
          sx={{
            flexDirection: 'column',
            backgroundColor: getBackgroundColorForChat(chat),
            padding: '16px',
            borderRadius: '0px 16px 16px 16px',
            width: messageFiles?.length && messageFiles[0]?.id ? 'fit-content' : 'auto',
            ...messageContainerStyles,
          }}
        >
          {loading && !displayResponse ? (
            <BeatLoader color={COLOR_PRIMARY} />
          ) : (
            <>
              {error ? (
                <ErrorMessageComponent errorMessage={error} />
              ) : (
                <>
                  <HorizontalScrollBox>
                    <Typography variant={'body2'}>
                      <pre
                        style={{
                          fontFamily: 'Poppins',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '14px',
                          lineHeight: '20px',
                          color: getTextColorForChat(chat),
                          whiteSpace: 'pre-wrap',
                          ...(messageContentStyles as React.CSSProperties),
                        }}
                        className={!isUserChat && !wasRestricted && !hideActions ? 'Chat-Response' : ''}
                      >
                        {isUserChat && wasRestricted ? (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {!showOriginalMessage && restrictedChat}
                            {showOriginalMessage && originalMessage}
                            {!showOriginalMessage && wasAnonymized && (
                              <IconButton
                                size={'small'}
                                sx={{ color: COLOR_WHITE }}
                                onClick={() => {
                                  setShowOriginalMessage(true);
                                }}
                              >
                                <Error />
                              </IconButton>
                            )}
                            {showOriginalMessage && (
                              <IconButton
                                size={'small'}
                                sx={{ color: COLOR_WHITE }}
                                onClick={() => {
                                  setShowOriginalMessage(false);
                                }}
                              >
                                <Close />
                              </IconButton>
                            )}
                          </div>
                        ) : !isUserChat && wasRestricted ? (
                          botChatInfo
                        ) : messageFiles?.length && messageFiles[0]?.id ? (
                          fileMessage
                        ) : (
                          messagesArray.map(message => {
                            if (!message.isCodeSnippet) {
                              return (
                                <StyledReactMarkdown
                                  // @ts-ignore
                                  remarkPlugins={[remarkGfm]}
                                  rehypePlugins={[rehypeKatex]}
                                  children={formatText(message.text) || ''}
                                />
                              );
                            }

                            if (message.language === 'base64Image') {
                              return <ShowImageComponent imageKey={message.text || ''} isBase64={true} />;
                            }

                            if (message.language === 'markdown' || checkIsMarkdownTable(message.text || '')) {
                              const canRenderTable = isMarkdownTableRenderable(message.text || '');

                              return (
                                <StyledReactMarkdown
                                  // @ts-ignore
                                  remarkPlugins={[remarkGfm, remarkMath]}
                                  rehypePlugins={[rehypeKatex]}
                                  children={(canRenderTable && message.text) || ''}
                                />
                              );
                            }

                            return (
                              <SyntaxHighlighter language={message.language} wrapLines showLineNumbers>
                                {message.text}
                              </SyntaxHighlighter>
                            );
                          })
                        )}

                        {!completedTyping && (
                          <StyledCursorSvg sx={{ backgroundColor: getTextColorForChat(chat) }} ref={caretRef} />
                        )}
                      </pre>
                      {files?.length && sourceDocuments?.length ? (
                        <ShowSourceDocumentsComponent sourceDocuments={sourceDocuments} files={files} />
                      ) : null}
                    </Typography>
                  </HorizontalScrollBox>
                  {files?.length && imageKey ? <ShowImageComponent imageKey={imageKey} /> : null}
                  {!isUserChat && !wasRestricted && !hideActions && <ChatActions chat={chat} />}
                </>
              )}
            </>
          )}
        </Flex>

        {isUserChat && wasRestricted && !hideActions && (
          <BlockedMessageActionComponent
            handleEditPrompt={() => {
              setMessageInput(message);
              handleEditPromptExtraAction && handleEditPromptExtraAction();
            }}
          />
        )}
        {messageFiles?.find(file => file.status === fileStatus.DONE) &&
          !hasUserStartedAskingQuestions &&
          isMostRecentUploadedFile && (
            <FileDoneProcessingContent file={messageFiles?.find(file => file.status === fileStatus.DONE)} />
          )}
        {messageFiles?.length
          ? messageFiles?.map((file, index) => (
              <div key={`file-status-${index}`}>
                {(file?.status === fileStatus.FAILED_PROCESSING || file?.status === fileStatus.FAILED_REDACTION) && (
                  <FileFailedProcessingComponent status={file?.status} />
                )}
                {file?.status === fileStatus.CANCELLED && <FileCancelledComponent />}
              </div>
            ))
          : null}
      </Stack>
    </Flex>
  );
};

const StyledReactMarkdown = styled(ReactMarkdown)`
  table {
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    border-color: inherit !important;
    display: block !important;
    width: auto !important;
    max-width: 100% !important;
    overflow: auto !important;
  }

  td,
  th {
    padding: 8px 16px !important;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    width: auto !important;
    border-color: inherit !important;
    border-style: solid !important;
    border-width: 1.5px !important;
  }
`;

const flicker = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const StyledCursorSvg = styled(Box)`
  display: inline-block;
  margin-left: 4px;
  height: 20px;
  width: 6px;
  margin-bottom: -4px;
  animation: ${flicker} 0.5s infinite;
`;

export const HorizontalScrollBox = styled(Box)`
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: rgba(217,217,227,.6);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(217,217,227,.8);
    border-radius: 8px;
  },
`;

export default observer(ChatMessage);
