import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Add } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { AppBar, PopoverProps, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { COLOR_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';
import { ConversationTypes } from '../../constants/conversationTypes';
import { fileStatus } from '../../constants/fileStatus';
import { Paths } from '../../constants/routes';

import { useStore } from '../../hooks/useStore';

import FileMenu from '../knowledgeBase/FileMenu';
import Button from '../buttons/Button';
import FlexRowCenter from '../utils/flex/FlexRowCenter';
import { getIconByName, TypeIcon } from '../knowledgeBase/utils';
import RenameModal from '../knowledgeBase/RenameModal';
import DeleteModal from '../knowledgeBase/DeleteModal';
import { getTextOverflowEllipsisStyles } from '../typography/utils';
import Flex from '../utils/flex/Flex';

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    min-height: 56px;
    padding: 0 32px;
  }
`;

const tabs = [
  {
    title: 'conversation.filePreview.original',
  },
  {
    title: 'conversation.filePreview.redacted',
  },
];

interface NavigationFilePreviewContainerProps {
  children: React.ReactNode;
}

const NavigationFilePreviewContainer = ({ children }: NavigationFilePreviewContainerProps) => {
  const {
    uiStore: { isNavigationDrawerExpanded, dimensions },
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: {
      selectedFile,
      setActiveFileTab,
      activeFileTab,
      updateFileName,
      deleteFile,
      currentKnowledgeBase,
    },
    modelStore: { organizationModels },
    conversationStore: { createConversation, conversationSettingsState },
  } = useStore();

  const navigate = useNavigate();

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [anchorMenuElement, setAnchorMenuElement] = useState<PopoverProps['anchorEl']>(null);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const open = Boolean(isMenuExpanded);

  const handleExpand = (event: { currentTarget: Element }) => {
    setIsMenuExpanded(prevState => !prevState);
    if (!isMenuExpanded) {
      setAnchorMenuElement(event.currentTarget);
    } else {
      setAnchorMenuElement(null);
    }
  };

  const handleCloseMenu = () => {
    setIsMenuExpanded(false);
    setAnchorMenuElement(null);
  };

  const onSaveUpdate = async (fileId: string, name: string) => {
    await updateFileName(fileId, name);
    setAnchorMenuElement(null);
  };

  const onDeleteFile = async () => {
    if (selectedFile && currentKnowledgeBase) {
      await deleteFile(selectedFile.id, currentKnowledgeBase.id);
      setAnchorMenuElement(null);
      goBack();
    }
  };

  const startNewConversation = async () => {
    if (selectedFile) {
      const fileModel = organizationModels.find(
        model => model.isDefaultFileModel === true
      );
      localStorage.setItem("knowledgeBaseSselectedFile", 'true');
      const conversation = await createConversation({
        type: ConversationTypes.Chat,
        model: fileModel?.modelVersion || 'gpt-4-file',
        temperature: conversationSettingsState.accuracy,
        maxTokens: conversationSettingsState.length,
        fileId: selectedFile?.id,
        chat: {
          files: [{ id: selectedFile?.id, name: selectedFile?.name }],
        },
      });

      if (conversation) {
        navigate(`${Paths.CHAT}/${conversation.id}`, { state: { dontRefresh: true } });
      }
    }
  };

  const goBack = () => navigate(-1);

  return (
    <Box sx={{ overflow: 'hidden', width: '100%' }}>
      <AppBar position={'fixed'}>
        <StyledToolbar>
          <Flex sx={{ gap: '8px', maxWidth: '45%' }}>
            <ArrowBackIcon
              sx={{ color: GRAY_COLORS.GRAY_5, width: '20px', height: '20px', cursor: 'pointer' }}
              onClick={goBack}
            />
            {selectedFile?.name && <TypeIcon src={getIconByName(selectedFile.name)} alt={selectedFile?.name} />}
            <Typography
              variant={'subtitle1'}
              sx={{
                color: GRAY_COLORS.GRAY_9,
                fontWeight: 700,
                marginLeft: '-10px',
                ...getTextOverflowEllipsisStyles(1),
              }}
            >
              {selectedFile?.name}
            </Typography>
          </Flex>

          <Flex sx={{ width: '33%' }}>
            <Tabs
              value={activeFileTab}
              onChange={(event, newValue) => setActiveFileTab(newValue)}
              sx={{
                border: 'none',
                borderRadius: '8px',
                backgroundColor: GRAY_COLORS.GRAY_1,
                padding: '4px',
                minHeight: '32px',
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: COLOR_WHITE,
                  height: '100%',
                  borderRadius: '6px',
                  zIndex: 100,
                },
              }}
            >
              {tabs.map((tab: { title: string }, index: number) => (
                <Tab
                  key={`home-page-tab-${index}`}
                  label={i18n.t(tab.title)}
                  id={`home-page-tab-${index}`}
                  aria-controls={`home-page-tab-panel-${index}`}
                  disabled={index === 1 && selectedFile?.status !== fileStatus.DONE}
                  sx={{
                    color: GRAY_COLORS.GRAY_7,
                    fontWeight: 400,
                    zIndex: 200,
                    minHeight: 'unset',
                    padding: '4px',
                    lineHeight: '20px',
                    width: '150px',

                    '&.Mui-selected': {
                      color: COLOR_PRIMARY,
                      fontWeight: 500,
                    },
                  }}
                />
              ))}
            </Tabs>
          </Flex>

          <Flex sx={{ gap: '16px', width: '33%', justifyContent: 'flex-end' }}>
            <Box>
              <Button onClick={startNewConversation}>
                <Add sx={{ marginRight: '8px' }} />
                {i18n.t('knowledgeBase.menu.startNewChat')}
              </Button>
            </Box>

            <IconButton
              onClick={handleExpand}
              sx={{
                width: '40px',
                height: '40px',
                border: '1px solid #D1D5DB',
                borderRadius: '6px',
              }}
            >
              <MoreVertOutlinedIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          </Flex>

          <FileMenu
            anchorEl={anchorMenuElement}
            open={open}
            handleClose={handleCloseMenu}
            options={[
              {
                name: i18n.t('knowledgeBase.menu.rename'),
                icon: <EditIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
                onClick: () => setShowRenameModal(true),
              },
              {
                name: i18n.t('knowledgeBase.menu.delete'),
                icon: <DeleteIcon sx={{ color: GRAY_COLORS.GRAY_6 }} />,
                onClick: () => setShowDeleteModal(true),
                itemSx: { borderTop: `1px solid ${GRAY_COLORS.GRAY_2}` },
              },
            ]}
          />
        </StyledToolbar>
      </AppBar>

      <RenameModal isOpen={showRenameModal} onClose={() => setShowRenameModal(false)} onSave={onSaveUpdate} />
      <DeleteModal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} onSave={onDeleteFile} />

      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: 1,
          transition: theme =>
            theme.transitions.create(['margin', 'width'], {
              easing: isNavigationDrawerExpanded ? theme.transitions.easing.sharp : theme.transitions.easing.easeOut,
              duration: isNavigationDrawerExpanded
                ? theme.transitions.duration.leavingScreen
                : theme.transitions.duration.enteringScreen,
            }),
          marginTop: '56px',
          minHeight: 'calc(100vh - 56px)',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default observer(NavigationFilePreviewContainer);
